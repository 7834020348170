
class ColorHSV {
    constructor () {
        this.h = -1
        //좌표 경도 Longitude
        this.s = -1
        //좌표 위도 Latitude
        this.l =  -1
    }
}

export default ColorHSV;