import ColorHSV from "./commonData"

import axios from "axios";


export function getIpGet(){

    // return axios.get("http://ip-api.com/json");
    return axios.get("https://api64.ipify.org?format=json");
}

export function getIpLatLng(ip){
    return axios.get(`https://ipapi.co/${ip}/json`);
    // return axios.get(`https://ip-api.com/json/${ip}`);
}

export function getDefaultMapZoomLevel(lang) {
    return lang == "en" ? 13 : 3
}

export function getRGBtoHSV(r, g, b) {
    var r_percent = r / 255;
    var g_percent = g / 255;
    var b_percent = b / 255;

    var max_color = 0;
    var min_color = 0;

    if(r_percent >= g_percent && r_percent >= b_percent) {
        max_color = r_percent
    }
    if(g_percent >= r_percent && g_percent >= b_percent) {
        max_color = g_percent
    }
    if(b_percent >= r_percent && b_percent >= g_percent) {
        max_color = b_percent
    }

    if(r_percent <= g_percent && r_percent <= b_percent) {
        min_color = r_percent
    }
    if(g_percent <= r_percent && g_percent <= b_percent) {
        min_color = g_percent
    }
    if(b_percent <= r_percent && b_percent <= g_percent) {
        min_color = b_percent
    }

    var l = 0;
    var s = 0;
    var h = 0;

    l = (max_color + min_color)/2;

    if(max_color == min_color) {
        s = 0;
        h = 0;
    } else {
        if(l < 0.5) {
            s = (max_color - min_color) / (max_color + min_color)
        } else {
            s = (max_color - min_color) / (2 - max_color - min_color)
        }

        if(max_color == r_percent) {
            h = (g_percent - b_percent) / (max_color - min_color)
        }

        if(max_color == g_percent) {
            h = 2 + (b_percent - r_percent) / (max_color - min_color)
        }

        if(max_color == b_percent) {
            h = 4 + (r_percent - g_percent) / (max_color - min_color)
        }
    }

    h = h * 60
    if(h < 0) h = h + 360

    var colorHSV = new ColorHSV();
   colorHSV.h = h;
   colorHSV.s = s;
   colorHSV.l = l
    return colorHSV;
}
 /**
     * accountId 종류 
     * @param {String} accountId 
     * @param {String} type 
     * 이미지 변경을 위해서 사용한다. 
     */
 export function getImageSrc(accountId,type){
   var rootUrl = "/images/" // 여기서 /src/assets를 같이주면 렌더가안됨. 렌더영역에서 assets를 더해줘야함.
   var imageUrl = ''
   var result = ''
   console.log(accountId,type) 
   switch(type){
        case 
        'login': //로그인 창 위에 뜨는 이미지 
            if(accountId == 'hsg'){
                imageUrl = 'enterprise_logo/hsg_logo.png'
            }else{
                imageUrl = 'login_logo_a.png'
            }
            break;

        case 'gnb' : //홈으로 돌아가는 아이콘 
            if(accountId == 'hsg'){
                imageUrl = 'enterprise_logo/hsg_logo.png'
            }else{
                imageUrl = 'top-logo.png'
            }
            break;

        case 'gnbTopImage' : //Gnb에 두번째로 뜨는애 
            if(accountId == 'hsg'){
                imageUrl = 'top-title-logo.png'
            }else{
                imageUrl = 'top-title-logo.png'
            }
            break;
   }
   result = rootUrl+imageUrl
   return result
}
export function getContent(accountId,type){
    var result = ""
    switch(type){
        case 'title': //Gnb에 두번째로 뜨는 이미지 밑에와 로그인 창에 나타나는 문구 
            if(accountId == 'hsg'){
                result = '드론영상 실시간 중계시스템'
            }else{
                result =  ''
            }
            break;
   }
   return result
}

